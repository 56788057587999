import { useFormik } from 'formik';
import * as Yup from 'yup';
import useRoute from '@vl/hooks/useGbRoute';
import { hook, bindings } from '@vl/redata';
import _ from 'lodash';
import CourseModel from '@uz/unitz-models/CourseModel';
import usePromiseSource from '@vl/hooks/usePromiseSource';

export default bindings({
  formContainer: {
    rules: [
      [
        'data',
        {
          data: {
            courseId: hook(() => {
              const route = useRoute();
              const params = _.get(route, 'params.redirect_url', {});
              const course_id = _.get(_.compact(_.split(params, 'id=')), '1', '');
              return course_id;
            }),
            '@item': hook((ctx) => {
              const course_id = ctx.get('courseId');
              const courseInfo = usePromiseSource(
                async () => {
                  try {
                    if (!course_id) return null;
                    const items = await CourseModel.find(
                      `where: {id: {_eq: "${course_id}"}}`,
                      ` photo_url
                        name
                        id
                        slug
                        advisor {
                          id
                          profile {
                            avatar_url
                            display_name
                          }
                          review_agg {
                            avg_score
                          }
                          reviews_aggregate {
                            aggregate {
                              count
                            }
                          }
                        }
                      `
                    );

                    return items.toObject();
                  } catch (err) {
                    console.log(err);
                  }
                },
                null,
                [course_id]
              );

              return _.get(courseInfo, '0', {});
            }),

            form: hook((ctx) => {
              const form = useFormik({
                initialValues: {
                  username: '',
                  password: '',
                },
                onSubmit: async (values, actions) => {
                  try {
                    await ctx.apply('authModel.emailLogin', values.username, values.password);
                  } catch (error) {
                    // apply translation
                    const code = _.get(error, 'code');
                    if (code) {
                      error.message = ctx.apply('i18n.t', `FirebaseMessage.${code}.message`);
                    }
                    actions.setStatus({
                      error,
                    });
                  }
                },
                validationSchema: Yup.object().shape({
                  username: Yup.string()
                    .email('Invalid email')
                    .required(ctx.apply('i18n.t', 'Form.required')),
                  password: Yup.string().required(ctx.apply('i18n.t', 'Form.required')),
                }),
              });

              return form;
            }),
            canSubmit: hook((ctx) => {
              return ctx.get('form.isValid') && !ctx.get('form.isSubmitting');
            }),
          },
        },
      ],
    ],
  },
});
