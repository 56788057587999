import React from 'react';
import { Button, Row, Col, Alert, Divider, Space, Checkbox } from 'antd';
import { Link } from '@uz/unitz-components-web/Link';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import gstyles from '@vl/gstyles';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import { Input, Form, FormItem } from 'formik-antd';
import styled from 'styled-components';
import CourseInfomationHeader from '@uz/unitz-components-web/CourseInfomationHeader';
import _ from 'lodash';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
};

const CustomInput = styled(FormItem)`
  .ant-input-affix-wrapper,
  .ant-input {
    border-radius: 8px !important;
    border-color: ${gstyles.colors.border} !important;
    &::-webkit-input-placeholder {
      color: ${gstyles.colors.sub};
    }
    &::-ms-input-placeholder {
      color: ${gstyles.colors.sub};
    }
    &::placeholder {
      color: ${gstyles.colors.sub};
    }
  }
`;

const CustomButton = styled.div`
  .ant-btn {
    border: 1px solid ${gstyles.colors.border};
  }
`;

export default () => (
  <DIV className="formContainer">
    <div
      className="bg-white500 rounded-lg pt-6"
      style={{
        boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1), 0px 1px 18px rgba(0, 0, 0, 0.06), 0px 6px 10px rgba(0, 0, 0, 0.08)',
      }}
    >
      <h3 className="text-2xl font-semibold text-center text-brandb500">{ctx.apply('i18n.t', 'Login.loginLabel')}</h3>

      {!_.isEmpty(ctx.get('@item')) && (
        <>
          <div className="text-sm text-main font-normal text-center mb-4">
            {ctx.apply('i18n.t', 'Signup.loginToRegisterCourse')}
          </div>
          <CourseInfomationHeader fullLayout />
        </>
      )}
      <div className="p-4 lg:p-6">
        <Row>
          {/* <Col className="mb-4 formStatus" span={24}>
          <CustomButton>
            <Button
              className="relative"
              block
              onClick={() => ctx.get('authModel.facebookLogin')(ctx.get('handlers.onLoginRedirect'))}
              loading={ctx.get('authModel.signInFacebook_')}
              disabled={ctx.get('authModel.signInFacebook_')}
            >
              <div className="absolute w-6 transform -translate-x-1/2 -translate-y-1/2 left-8 top-1/2">
                {gstyles.icons({ name: 'facebook2', size: 24 })}
              </div>
              <h5 className="mb-0 text-base font-semibold">{ctx.apply('i18n.t', 'Login.facebookLogin')}</h5>
            </Button>
          </CustomButton>
        </Col> */}
          <Col className="mb-4 formStatus" span={24}>
            <CustomButton>
              <Button
                className="relative"
                type="secondary"
                onClick={() => ctx.get('authModel.googleLogin')(ctx.get('handlers.onLoginRedirect'))}
                loading={ctx.get('authModel.signInGoogle_')}
                disabled={ctx.get('authModel.signInGoogle_')}
                block
              >
                <div className="absolute w-6 transform -translate-x-1/2 -translate-y-1/2 left-8 top-1/2">
                  {gstyles.icons({ name: 'google2', size: 24 })}
                </div>
                <h5 className="mb-0 inline-block text-base font-semibold">
                  {ctx.apply('i18n.t', 'Login.googleLogin')}
                </h5>
              </Button>
            </CustomButton>
          </Col>
          {/* <Col className="mb-4 formStatus" span={24}>
          <CustomButton>
            <Button
              className="relative"
              block
              onClick={() => ctx.get('authModel.appleLogin')(ctx.get('handlers.onPrevScreen'))}
              loading={ctx.get('authModel.signInApple_')}
              disabled={ctx.get('authModel.signInApple_')}
            >
              <div className="absolute w-6 transform -translate-x-1/2 -translate-y-1/2 left-8 top-1/2">
                {gstyles.icons({ name: 'apple2', size: 24 })}
              </div>
              <h5 className="mb-0 text-base font-semibold">{ctx.apply('i18n.t', 'Login.appleLogin')}</h5>
            </Button>
          </CustomButton>
        </Col> */}
        </Row>
        <FormProvider form={ctx.get('form')}>
          <Form {...layout} className="login-form" id="login-form">
            <Row>
              <Col className="formStatus" span={24}>
                {!!ctx.get('form.status.error') && (
                  <div className="py-2">
                    <Alert message={ctx.get('form.status.error.message')} type="error" />
                  </div>
                )}
                {!!ctx.get('form.status.success') && (
                  <div className="py-2">
                    <Alert message={ctx.get('form.status.success.message')} type="success" />
                  </div>
                )}
              </Col>
            </Row>
            <Divider plain>
              <div className="mb-0 text-sm font-normal uppercase">{ctx.apply('i18n.t', 'Login.or')}</div>
            </Divider>
            <CustomInput name="username">
              <Input name="username" placeholder={ctx.apply('i18n.t', 'Login.email')} size="large" />
            </CustomInput>
            <CustomInput name="password">
              <Input.Password
                iconRender={(visible) =>
                  visible
                    ? gstyles.icons({ name: 'eye-filled', fill: gstyles.colors.sub, size: 24 })
                    : gstyles.icons({ name: 'eye-invisible-filled', fill: gstyles.colors.sub, size: 24 })
                }
                name="password"
                placeholder={ctx.apply('i18n.t', 'Login.password')}
                size="large"
              />
            </CustomInput>
            <Row className="mb-3">
              <Col xs={12} lg={16}>
                <Checkbox>
                  <div className="mb-0 text-base text-main font-normal">{ctx.apply('i18n.t', 'Login.saveAccount')}</div>
                </Checkbox>
              </Col>
              <Col xs={12} lg={8} className="text-right">
                <Link to={ctx.apply('routeStore.toLocale', '/forgot')}>
                  <h5 className="mb-0 font-medium text-base text-brandb500">
                    {ctx.apply('i18n.t', 'Login.forgotPassword')}
                  </h5>
                </Link>
              </Col>
            </Row>
            <Row className="mt-6 mb-8">
              <Col span={24}>
                <Button
                  className="shadow-sm"
                  size="large"
                  type="primary"
                  htmlType="submit"
                  id="submit"
                  disabled={!ctx.get('canSubmit')}
                  loading={ctx.get('form.isSubmitting')}
                  block
                >
                  <span id="button-text">{ctx.apply('i18n.t', 'Login.login')}</span>
                </Button>
              </Col>
            </Row>
          </Form>

          <div className="mt-3 text-center">
            <Space>
              <div className="font-normal text-main mb-0 text-base">{ctx.apply('i18n.t', 'Login.signupQuestion')}</div>
              <Link to={ctx.apply('routeStore.toLocale', '/signup')}>
                <h5 className="mb-0 font-medium text-base text-brandb500">
                  {ctx.apply('i18n.t', 'Login.signupRedirect')}
                </h5>
              </Link>
            </Space>
          </div>
        </FormProvider>
      </div>
    </div>
  </DIV>
);
